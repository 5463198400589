import './../../App.css'
import ModalForm from '../common/modalForm'
import { userBALService } from '../../bal/user.bal'
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import TorGrid from '../torgrid/torgrid';
import ToastMessage from "../deals/components/toastMessage";
import { Prompt } from "react-router-dom";
import { taskInstanceBALService } from '../../bal/taskInstance.bal';
import history from '../../history/history';

//https://fontawesome.com/icons?d=gallery
//https://stackoverflow.com/questions/56559772/where-do-i-find-the-object-names-of-icons-in-the-fontawesome-free-packages
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faPlusCircle } from "@fortawesome/free-solid-svg-icons";

class TaskInstanceDashboard extends Component {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.timeout = 250;
        this.gridApi = null;

        const today = new Date();
        const yesterday = new Date(today);

        yesterday.setDate(yesterday.getDate() - 14);

        this.state = {
            ...userBALService.getUserDetails(),
            rowData: [],
            id: null,
            isOpenGrid: true,
            modalVisible: false,
            modalType: 'close',
            modalHeading: '',
            modalHandler: '',
            errorList: [],
            messageHeader: "",
            showMessage: false,
            gridData: [],
            editType: 'fullRow',
            existingTaskInstance: [],
            newTaskInstance: [],
            dealList: [],
            editorState: null,  
            selectedRows: null
        };
        this.render = this.render.bind(this);
        this.closeMessage = this.closeMessage.bind(this);
        this.setAgGridAPI = this.setAgGridAPI.bind(this);
        this.getPage = this.getPage.bind(this);
        this.addNew = this.addNew.bind(this);
        this.refresh = this.refresh.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleRowSelected = this.handleRowSelected.bind(this);
        this.refresh();
    }

    render() {
        if (this.state.redirectTo)
            return <Redirect to={this.state.redirectTo} />;
        return this.getPage();
    }

    closeModal() {
        if (this.state.modalHandler === 'refresh') {
            window.location.reload();
        }
        this.setState({
            modalVisible: false
        });
    }

    closeMessage() {
        this.setState({ showMessage: false, errorList: [], messageHeader: "" });
    }

    setAgGridAPI(api) {
        this.gridApi = api;
        this.gridApprovalApi = api;
    }

    addNew = async () => {
        history.push('/taskInstance');
        window.location.pathname = '/taskInstance';
    }

    refresh() {
        taskInstanceBALService.getRowData().then(data => {
            let showGrid = false;
            if(data && data.length > 0) {
                data = data.map(row => { return {
                            ...row, 
                            existingValue: row?.value
                    }
                });
                showGrid = true;
            }
           this.setState({
                gridData: data,
                showGrid: showGrid
            });
        }, error => {
            this.setState({ 
                showMessage: true, 
                errorList: [error], 
                messageHeader : `Error while retrieving Task Instance...`,
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'close',
                modalHeading: 'Error while retrieving Task Instance!'
            }); 
            console.log(error);
        });
    }

    handleRowSelected(e) { 
        const { data } = e.target;
        console.log(data);
        this.setState({ redirectTo:{pathname : '/taskInstance', state : {data : data } } });
    }

    getPage() {
        const { modalType, showGrid, modalVisible, modalHeading, gridData, existingTaskInstance, newTaskInstance } = this.state;
        return (
            <div style={{ gridTemplateRows: 'auto auto 1fr', display: 'grid', height: '100%', width: '100%' }} >
                {
                    this.state.showMessage ?
                        <ToastMessage
                            header={this.state.messageHeader}
                            errorList={this.state.errorList}
                            closeMessage={this.closeMessage}
                        /> : <div></div>
                } 
                <div>
                    <div style={{ float: "Left", padding: "5px 15px 0px 15px", cursor: "pointer" }} ><h4><u>Task Instance</u></h4></div>
                    <div style={{ float: "right", paddingRight: "15px", cursor: "pointer" }} onClick={this.addNew}><FontAwesomeIcon icon={faPlusCircle} /></div>
                    <div style={{ float: "right", paddingRight: "15px", cursor: "pointer" }} onClick={this.refresh}><FontAwesomeIcon icon={faSync} /></div>
                </div>
                { 
                    showGrid? 
                    <div style={{ height: '100%', width: '100%' }}>
                        <TorGrid
                            setAgGridAPI = {this.setAgGridAPI}
                            isHideDateInputs={true}
                            domLayout="normal"

                            rowData={gridData}

                            className="ag-theme-alpine"
                            rowSelection="multiple"
                            groupDefaultExpanded = {-1}
                            groupIncludeTotalFooter={false} 

                            guikey="taskInstance"
                            gridIdentityColumn="id"
                            gridLinkColumn="Item"
                            //gridApproveColumn = "Changes"
                            handleRowSelected={this.handleRowSelected}
                            //isApprover = {isApprover && inApproval}
                            approve = {this.approve}
                            reject = {this.reject}
                            showReject = {true} 

                            isRowSelectable={ (node) => { 
                                return false }}
                        />                        
                        
                    </div>:<div><b>Task Instance - No rows to display</b></div>
                }
                <Prompt when={this.state.isOpenGrid === false && JSON.stringify(existingTaskInstance) !== JSON.stringify(newTaskInstance)}
                    message="Do you want to discard changes?"/>
                <ModalForm modalType={modalType}
                    modalVisible={modalVisible}
                    modalHeading={modalHeading}
                    onClickAway={() => this.closeModal()}
                    onClickClose={() => this.closeModal()}
                    onClickYes={() => this.handleModalChange()}
                />
            </div>
        );
    }
}

export default TaskInstanceDashboard;