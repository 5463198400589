import { Utils } from '../helper/utils';
import { userBALService } from './user.bal';
const {config} = require('../config');
const getColumnList = async (params) => {
    return  {
        'columnSpecs': [
            {displayIndex: 1, 'display': 'Task Instance Id', 'col': 'id','ag-grid': true, hide:true, width: 200 },
            {displayIndex: 2, 'display': 'Item', 'col': 'name','ag-grid': true, width: 200 },
            {displayIndex: 3, 'display': 'For Date', 'col': 'forDate', 'format': 'dateFormat', 'ag-grid': true, width: 200 },
            {displayIndex: 4, 'display': 'Status', 'col': 'status','ag-grid': true, width: 200 },
            {displayIndex: 5, 'display': 'Completion Date', 'col': 'completionDate', 'format': 'dateFormat', 'ag-grid': true, width: 200 },
            {displayIndex: 6, 'display': 'Soft Deadline', 'col': 'softDeadline', 'format': 'dateFormat', 'ag-grid': true, width: 200 },
            {displayIndex: 7, 'display': 'Hard Deadline', 'col': 'hardDeadline', 'format': 'dateFormat', 'ag-grid': true, width: 200 },
            {displayIndex: 8, 'display': 'Entity', 'col': 'entity','ag-grid': true, width: 200 },
            {displayIndex: 9, 'display': 'Jurisdiction / Regulator', 'col': 'jurisdictionRegulator', 'ag-grid': true, width: 200 },
            {displayIndex: 10, 'display': 'Frequency', 'col': 'frequency','ag-grid': true, width: 200 },
            {displayIndex: 11, 'display': 'External Service Provider Involved, If Any', 'col': 'externalServiceProvider','ag-grid': true, width: 200 },
            {displayIndex: 12, 'display': 'Contact Of Service Provider', 'col': 'externalContactName','ag-grid': true, width: 200 },
            {displayIndex: 13, 'display': 'Primary Person', 'col': 'primaryPerson','ag-grid': true, width: 200 },
            {displayIndex: 14, 'display': 'Secondary Person', 'col': 'secondaryPerson','ag-grid': true, width: 200 },
            {displayIndex: 15, 'display': 'Tertiary Person', 'col': 'tertiaryPerson','ag-grid': true, width: 200 },
            {displayIndex: 16, 'display': 'Link', 'col': 'link','ag-grid': true, width: 200 },
            {displayIndex: 17, 'display': 'Remark Instance', 'col': 'remarkInstance','ag-grid': true, width: 200 },
            {displayIndex: 18, 'display': 'Remark Master', 'col': 'remarkMaster','ag-grid': true, width: 200 },
            {displayIndex: 19, 'display': 'End Date', 'col': 'endDate','ag-grid': true, 'format': 'dateFormat', width: 200 },
            {displayIndex: 20, 'display': 'Has Soft Deadline', 'col': 'hasSoftDeadline','ag-grid': true, width: 200 },
            {displayIndex: 21, 'display': 'Last Updated By Instance', 'col': 'lastUpdatedByInstance','ag-grid':true, hide:true, width: 200 },
            {displayIndex: 22, 'display': 'Last Updated By Master', 'col': 'lastUpdatedByMaster','ag-grid':true, hide:true, width: 200 },
            {displayIndex: 23, 'display': 'Task Detail Id', 'col': 'taskDetailId','ag-grid': true, hide:true, width: 200 },
            {displayIndex: 24, 'display': 'Entity Id', 'col': 'entityId','ag-grid': true, hide:true, width: 200 },
            {displayIndex: 25, 'display': 'Jurisdiction Id', 'col': 'jurisdictionId','ag-grid': true, hide:true, width: 200 },
            {displayIndex: 26, 'display': 'Frequency Id', 'col': 'frequencyId','ag-grid': true, hide:true, width: 200 },
            {displayIndex: 27, 'display': 'External Id', 'col': 'externalId','ag-grid': true, hide:true, width: 200 },
            {displayIndex: 28, 'display': 'Primary User Id', 'col': 'primaryUserId','ag-grid': true, hide:true, width: 200 },
            {displayIndex: 29, 'display': 'Secondary User Id', 'col': 'secondaryUserId','ag-grid': true, hide:true, width: 200 },
            {displayIndex: 30, 'display': 'Tertiary User Id', 'col': 'tertiaryUserId','ag-grid': true, hide:true, width: 200 },
            {displayIndex: 31, 'display': 'Last Updated By Id Instance', 'col': 'lastUpdatedByIdInstance','ag-grid': true, hide:true, width: 200 },
            {displayIndex: 32, 'display': 'Last Updated By Id Master', 'col': 'lastUpdatedByIdMaster','ag-grid': true, hide:true, width: 200 },
        ]};
}

export const taskInstanceBALService = {
    getColumnList,
    getRowData,
    getAutoGroupColumnDef,
    save
};

function getAutoGroupColumnDef(params) {
    return {
        headerName: 'Group',
        field: 'name',
        width: 600,
        pinned: 'left',
      };    
}

function getRowData(params) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/taskInstance/getAll`, requestOptions).then(Utils.handleResponse);
}

function save(row) {
    //row = Utils.removeBlanksNotZero(row);
    return fetch(`${config.apiDDSUrl}/addTaskInstance`, { body: JSON.stringify(row), method: 'PUT', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' } }).then( data => { return Utils.handleResponse(data) });
}
